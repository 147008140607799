<template>
	<section id="projects">
		<h2 class="sub-head">Projects</h2>
		<div class="projects-container">
			<div v-for="(item, index) in projects" :key="index" :style="{backgroundImage: 'url('+imageURLs[index]+')'}" class="project-image-card">
				<div class="details">
					<p class="project-name">{{ item.projectName }}</p>
					<p class="project-description">{{ item.projectDescription }}</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import firebase from 'firebase/compat';

export default {
	name: 'Projects',
	data() {
		return {
			projects: this.$store.state.projects,
			imageURLs: [],
		}
	},
	methods: {
		async getImage(imageName) {
			const ref = firebase.storage().ref('projects/' + imageName);
			const url = await ref.getDownloadURL()
			return url;
		},
		loadData() {
			const promiseArray = this.projects.map(item => this.getImage(item.imageName));
			Promise.all(promiseArray)
			.then((valueArray) => {
				this.imageURLs = valueArray;
			})
		}
	},
	mounted() {
		this.loadData();
	}
}
</script>

<style lang="scss" scoped>
#projects {
	padding: 5rem 0;
	background-color: #358bc4;
	min-height: auto;
}

.projects-container {
	padding: 0 10vw;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 2rem;
}
.project-image-card {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	max-width: 25rem;
	height: 20rem;
	position: relative;
	border-radius: 1rem;
	&:hover {
		.details {
			animation: slide-up 700ms ease-in-out forwards;
			z-index: 0;
		}
		@keyframes slide-up {
			100% {
				opacity: 1;
				visibility: visible;
				transform: translate(0);
			}
		}
	}
}
.details {
	position: absolute;
	color: white;
	text-align: left;
	font-weight: 500;
	padding: 1rem;
	visibility: hidden;
	transform: translate(0, 40%);
	border-radius: 1rem;
	background-color: rgba(55, 53, 196, 0.86);
	overflow: overlay;
	max-height: 20rem;
	width: 100%;
	p {
		margin: 0;
	}
	.project-name {
		font-size: 1.7rem;
		font-weight: 600;
		margin-bottom: 1rem;
	}
	.project-description {
		white-space: pre-wrap;
	}
	&:not(:hover) {
		animation: slide-out 700ms ease-in-out forwards;	
		@keyframes slide-out {
			from {
				opacity: 1;
				visibility: visible;
				transform: translate(0);
			}
			to {
				opacity: 0;
				visibility: hidden;
				transform: translate(0, 40%);
			}
		}
	}
}

@media screen and (max-width: 1400px) {
	.projects-container {
		grid-template-columns: repeat(3, 1fr);
	}
}
@media screen and (max-width: 1366px) {
	.projects-container {
		grid-template-columns: repeat(3, 1fr);
	}
}
@media screen and (max-width: 1020px) {
	.projects-container {
		grid-template-columns: repeat(2, 1fr);
	}
}
@media screen and (max-width: 720px) {
	.projects-container {
		grid-template-columns: repeat(1, 1fr);
	}
	.project-image-card {
		max-width: initial;
	}
}
</style>