<template>
	<section id="experience">
		<h2 class="sub-head" @click="$emit('toAdmin', 'expClick')">Experience</h2>
		<swiper 
			:effect="'coverflow'" 
			:grabCursor="true" 
			:centeredSlides="true" 
			:slidesPerView="'auto'" 
			:coverflowEffect='{
				"rotate": 50,
				"stretch": 0,
				"depth": 100,
				"modifier": 1,
				"slideShadows": true
			}' :pagination="true">
			<swiper-slide class="card col-sm-12" style="max-width: 25rem;" v-for="item in experiences" :key="item">
				<div class="card-header">
					<strong>{{ item.designation }}</strong>
					<p class="card-text">{{ item.period }}</p>    
				</div>
				<div class="card-body">
					<h5 class="card-title"><i class="far fa-building" style="margin-right: 10px;"></i>{{ item.companyName }}</h5>
					<p class="card-text">{{ item.description }}</p>
					<div v-if="item.links !== null">
						<div class="card-text" :key="index" v-for="(link, index) in item.links">
							<i class="fas fa-link" style="margin-right: 10px"></i>
							<a :href="link.url"
								target="_blank"
								>{{ link.text }}</a>
						</div>
					</div>
				</div>
			</swiper-slide>
		</swiper>
	</section>
</template>

<script>
// import Swiper core and required modules
import SwiperCore, { Pagination, A11y, EffectCoverflow } from 'swiper';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// install Swiper modules
SwiperCore.use([ Pagination, A11y, EffectCoverflow]);

// Import Swiper styles
import 'swiper/swiper.scss';
require('swiper/components/pagination/pagination.scss');
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';

export default {
	name: 'Experience',
	components: {
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			experiences: this.$store.state.experiences
		};
	}
}
</script>

<style>
#experience {
	background-color: #c78014;
	padding: 1.5rem 0;
	min-height: auto;
}

a {
	text-align: left;
}
.row {
	justify-content: center;
	margin: 0 10px;
	border: 0;
}
.card {
	margin: 10px;
	border-radius: 20px;
}
.card-header {
	text-align: left;
	font-size: 1.5em;
}
.card-title {
	text-align: left;
	font-style: italic;
}
.card-text {
	text-align: left;
	font-size: 1rem;
}
.col-sm-12 {
	padding: 0;
}

/* Swiper classes */
.swiper-slide {
	height: auto;
}

.swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow {
	border-radius: 20px;
}
</style>