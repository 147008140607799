<template>
	<section id="about" ref="about">
		<h2 class="sub-head">About me</h2>
		<p 
			v-for="(item, index) in about" 
			:key="item" 
			class="about"
			:class="{'about--other-color': index % 2 !== 0}"
			>{{ item }}</p>
		<nav class="about-icons">
			<a class="socialmedia" href="https://www.linkedin.com/in/rtm909/" target="_blank">
				<i class="fab fa-linkedin fa-4x"></i>
			</a>
			<a class="socialmedia" href="https://github.com/rtm909" target="_blank">
				<i class="fab fa-github fa-4x"></i>
			</a>
			<a class="socialmedia" href="https://gitlab.com/rtm909" target="_blank">
				<i class="fab fa-gitlab fa-4x"></i>
			</a>
			<a class="socialmedia" href="https://codepen.io/rtm909" target="_blank">
				<i class="fab fa-codepen fa-4x"></i>
			</a>
			<a class="socialmedia" href="https://www.facebook.com/rtm909/" target="_blank">
				<i class="fab fa-facebook fa-4x"></i>
			</a>
			<a class="socialmedia" href="https://www.instagram.com/rahul_mehta_/" target="_blank">
				<i class="fab fa-instagram fa-4x"></i>
			</a>
			<a class="socialmedia" href="https://twitter.com/RTM1207" target="_blank">
				<i class="fab fa-twitter fa-4x"></i>
			</a>
		</nav>
	</section>
</template>

<script>
export default {
	name: 'AboutMe',
	data() {
		return {
			about: this.$store.state.about
		}
	}
}
</script>


<style lang="scss" scoped>

#about {
	background-color: #C62368;
}

.about{
	margin-right: 80px;
	margin-left: 80px;
	font-size: 1.5em;
	color: white;
	text-align: center;
}
.about--other-color {
	color: #E5FF9E;
}
.about-icons {
	text-align: center;
}
.socialmedia{
	color: white;
	padding: 0 40px;
	transition: all 200ms ease-in-out;
}

.slide-up-left, .slide-down-right {
	display: none;   
}
.socialmedia:hover {
	color: #E5FF9E;
	border-radius: 100px;
	transition: color 1s ease-out;
	transition: background-color 1s ease-out;
	.fab {
		transform: scale(1.1);
		transition: transform 100ms ease-out;
	}
}
.fab {
	margin-top: 22px;
	position: relative;
}

</style>