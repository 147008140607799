import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/compat/app';

const firebaseConfig = {
	apiKey: "AIzaSyAs2qStDYSIQ7h4RKP9HshWcN5-55s8L_Y",
	authDomain: "portfolio-a4ad3.firebaseapp.com",
	databaseURL: "https://portfolio-a4ad3-default-rtdb.firebaseio.com",
	projectId: "portfolio-a4ad3",
	storageBucket: "portfolio-a4ad3.appspot.com",
	messagingSenderId: "215786152250",
	appId: "1:215786152250:web:c813b96bd4e11d968b7003",
	measurementId: "G-PWC7MHBQ2M"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

let app;

firebase.auth().onAuthStateChanged(() => {
	if(!app){
		app = createApp(App).use(store).use(router).mount('#app') 		
	}
})
