import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import firebase from 'firebase/compat'
import store from '../store'
require('firebase/auth')


const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {loading: true}
	},
	{
		path: '/admin-login',
		name: 'AdminLogin',
		component: () => import(/* webpackChunkName: "adminPanel" */ '../views/AdminLogin.vue'),
	},
	{
		path: '/admin',
		redirect: '/admin/designation',
		name: 'AdminPanel',
		component: () => import(/* webpackChunkName: "adminPanel" */ '../views/AdminPanel.vue'),
		meta: {requiresAuth: true, loading: true},
		children: [
			{
				path: '/admin/change-password',
				name: 'ChangePassword',
				component: () => import(/* webpackChunkName: "changePassword" */ '../components/admin/ChangePassword.vue'),
			},
			{
				path: '/admin/introduction',
				name: 'EditIntroduction',
				component: () => import(/* webpackChunkName: "editIntroduction" */ '../components/admin/EditIntroduction.vue'),
			},
			{
				path: '/admin/designation',
				name: 'EditDesignation',
				component: () => import(/* webpackChunkName: "editIesignation" */ '../components/admin/EditDesignation.vue'),
			},
			{
				path: '/admin/experience',
				name: 'EditExperience',
				component: () => import(/* webpackChunkName: "editDesignation" */ '../components/admin/EditExperience.vue'),
			},
			{
				path: '/admin/skills',
				name: 'EditSkills',
				component: () => import(/* webpackChunkName: "editSkills" */ '../components/admin/EditSkills.vue'),
			},
			{
				path: '/admin/projects',
				name: 'EditProjects',
				component: () => import(/* webpackChunkName: "editProjects" */ '../components/admin/EditProjects.vue'),
			},
			{
				path: '/admin/contact-me',
				name: 'EditContactMe',
				component: () => import(/* webpackChunkName: "editContactMe" */ '../components/admin/EditContactMe.vue'),
			},
		]
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import(/* webpackChunkName: "notFound" */ '../components/NotFound.vue'),
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
	const isAuthenticated = firebase.auth().currentUser;
	const loading = to.matched.some(record => record.meta.loading);

	if(store.state.designation.length === 0 && loading){
		store.dispatch('fetchPortfolioData')
		.then(next);
	} else if(requiresAuth && !isAuthenticated) {
		next('/admin-login');
	} else {
		next()
	}
})

export default router
