import { createStore } from 'vuex'

export default createStore({
	state: {
		designation: '',
		about: [],
		experiences: [],
		skills: '',
		skillsExcerpt: '',
		projects: [],
		contactMeLine: '',
		fileName: ''
	},
	mutations: {
		'UPDATE_DESIGNATION' (state, designation) {
			state.designation = designation;
			console.log('Designation changes saved!');
		},
		'UPDATE_ABOUT' (state, about) {
			state.about = about;
			console.log('About changes saved!');
		},
		'UPDATE_EXPERIENCES' (state, experiences) {
			state.experiences = experiences;
			console.log('Experience changes saved!');
		},
		'UPDATE_SKILLS' (state, skills) {
			state.skills = skills;
			console.log('Skill changes saved!');
		},
		'UPDATE_SKILLS_EXCERPT' (state, skillsExcerpt) {
			state.skillsExcerpt = skillsExcerpt;
			console.log('Skill excerpt changes saved!');
		},
		'UPDATE_PROJECTS' (state, projects) {
			state.projects = projects;
			console.log('Project changes saved!');
		},
		'UPDATE_CONTACT_ME_LINE' (state, line) {
			state.contactMeLine = line;
			console.log('ContactMe line changes saved!');
		},
		'UPDATE_FILE' (state, fileName) {
			state.fileName = fileName;
			console.log('File name changes saved!');
		},
		'UPDATE_STATE' (state, portfolio) {
			state.designation = portfolio.designation;
			state.about = portfolio.about;
			state.experiences = portfolio.experiences;
			state.skills = portfolio.skills;
			state.skillsExcerpt = portfolio.skillsExcerpt;
			state.projects = portfolio.projects;
			state.contactMeLine = portfolio.contactMeLine;
			state.fileName = portfolio.fileName;
			console.log('State Updated');
		},
	},
	actions: {
		updateDesignation({commit}, designation){
			commit('UPDATE_DESIGNATION', designation);
		},
		updateAbout({commit}, about){
			commit('UPDATE_ABOUT', about);
		},
		updateExperiences({commit}, experiences){
			commit('UPDATE_EXPERIENCES', experiences);
		},
		updateSkills({commit}, skills){
			commit('UPDATE_SKILLS', skills);
		},
		updateSkillsExcerpt({commit}, skillsExcerpt){
			commit('UPDATE_SKILLS_EXCERPT', skillsExcerpt);
		},
		updateProjects({commit}, projects){
			commit('UPDATE_PROJECTS', projects);
		},
		updateContactMeLine({commit}, line){
			commit('UPDATE_CONTACT_ME_LINE', line);
		},
		updateFileName({commit}, fileName){
			commit('UPDATE_FILE', fileName);
		},
		updateState({commit}, portfolio){
			commit('UPDATE_STATE', portfolio);
		},
		fetchPortfolioData({commit}) {
			return fetch('https://portfolio-a4ad3-default-rtdb.firebaseio.com/portfolio.json')
			.then(res => res.json())
			.then((portfolio) => {
				commit('UPDATE_STATE', portfolio)
			})
		}
	},
	modules: {
	}
})
