<template>
	<transition
		name="home-router-view-transition"
		enter-active-class="animated fadeIn"
		leave-active-class="animated fadeOut"
		mode="out-in">
		<router-view></router-view>
	</transition>
</template>

<style lang="scss">
// Head font
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

// Base font
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400&display=swap');

#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}

body, html {
	font-family: 'Tajawal', sans-serif;
	background-color: black;
	scroll-behavior: smooth;
}

.sub-head {
	color: white;
	font-size: 2.7em;
	font-weight: 700;
	margin-bottom: 2rem;
}

// Scroll bar styling
/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

</style>
