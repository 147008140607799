<template>
	<section id="skills">
		<h2 class="sub-head">Skills</h2>
		<div class="container">
			<div class="skills-excerpt" @click="$emit('toAdmin', 'skillExcerptClick')">{{ excerpt }}</div>
			<div class="skills-container">
				<div class="skill" v-for="skill in skills" :key="skill">
					{{ skill }}
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'Skills',
	data() {
		return {
			skills: this.$store.state.skills.split(', '),
			excerpt: this.$store.state.skillsExcerpt
		}
	}
}
</script>

<style scoped>
#skills {
	min-height: auto;
	margin: 5rem 0;
}

.container {
	display: grid;
	justify-content: center;
	align-items: center;
	color: white;
	grid-auto-flow: column;
	grid-template-columns: 50%;
	margin-top: 3rem;
	padding: 0;
}

.skills-container {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 1.2rem;
}
.skill {
	padding: 1.1rem;
	border: 0.5rem double darkcyan;
	border-radius: 1rem;
	border-style: outset;
}
.skills-excerpt {
	text-align: left;
	margin-right: 1.5rem;
	white-space: break-spaces;
	font-size: 1.1rem;
}

@media screen and (max-width: 790px) {
	.container {
		grid-auto-flow: row;
		grid-template-columns: 100%;
	}
	.skills-excerpt {
		margin: 0 20px 2rem 20px;
	}
	.skills-container {
		justify-content: center;
	}
}
</style>