<template>
	<footer id="footer">
		<div class="">
			<div class="footer-list">
				<a class="footer-link" href="https://www.linkedin.com/in/rtm909/" target="_blank">linkedin</a>
				<a class="footer-link" href="https://github.com/rtm909" target="_blank">github</a>
				<a class="footer-link" href="https://gitlab.com/rtm909" target="_blank">gitlab</a>
				<a class="footer-link" href="https://www.codepen.io/rtm909/" target="_blank">codepen</a>
				<a class="footer-link" href="https://www.facebook.com/rtm909" target="_blank">facebook</a>
				<a class="footer-link" href="https://www.instagram.com/rahul_mehta_/" target="_blank">instagram</a>
				<a class="footer-link" href="https://twitter.com/RTM1207" target="_blank">twitter</a>
			</div>
			<div class="footer-list" @click="$emit('toAdmin', 'footerClick')">
				<p>© {{ new Date().getFullYear() }} <a class="footer-link" href="">Rahul Mehta</a>. All rights reserved.</p>
			</div>
		</div>
	</footer>
</template>

<style lang="scss" scoped>
$hover-color: darkorange;

p {
	margin-top: 2.5rem;
	font-weight: 600;
}

footer {
	background: darkcyan;
	padding-top: 3rem;
}

.footer-list {
	overflow: hidden;
	margin: 0 auto;
	max-width: 90%;
	padding: 0;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.footer-link {
	width: 20%;
	padding: 1em 0;
	color: white;
	text-align: center;
	text-decoration: none;
	color: #ffffff;
	font-size: 1.3em;
	font-weight: 600;
	transition: all 100ms ease-in-out;
}
.footer-link:hover {
	color: $hover-color;
	transform: scale(1.1);
}

@media (max-width: 550px) {
	.footer-link {
		width: 50%;
		padding: 0.5em 0;
	}
}
</style>