<template>
	<section id="contact-me">
		<h2 class="sub-head">Let's get in touch!</h2>
		<p class="about">{{ contactMeLine }}</p>
		<a :href="fileURL" target="_blank" class="resume-wrapper">
			<i class="fas fa-address-card">
				<p class="resume">Resume</p>
			</i>
		</a>
		<div class="message">
			<form method="post" class="form" action="https://formspree.io/mrgyrkbd">
				<div 
					class="form-inputs" 
					:class="{'reveal-inputs': showForm}">
					<input 
						type="text"
						name="name"
						id="name"
						placeholder="Name*" required />
					<input 
						type="email" 
						name="from" 
						id="email" 
						placeholder="Email*"
						pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$" required>
					<textarea 
						name="message" 
						id="messarea" 
						placeholder="Message*" required></textarea>
				</div>
				<button 
					@click="revealForm"
					:type="buttonText == 'Send Email' ? 'submit' : ''" 
					class="button expand" 
					id="send"><strong>{{ buttonText }}</strong></button>
			</form>
		</div>
	</section>
</template>

<script>
import firebase from 'firebase/compat';
export default ({
	data() {
		return {
			showForm: false,
			buttonText: 'Show Contact Form',
			contactMeLine: this.$store.state.contactMeLine,
			fileURL: ''
		}
	},
	methods: {
		revealForm() {
			this.showForm = true;
			this.buttonText = 'Send Email'
		},
		async loadData() {
			let name = this.$store.state.fileName;
			const ref = firebase.storage().ref().child(name);
			const url = await ref.getDownloadURL()
			this.fileURL = url;
		}
	},
	mounted() {
		this.loadData();
	}
})
</script>

<style lang="scss" scoped>
$color: aquamarine;

#contact-me {
	min-height: auto;
	padding: 7rem 0;
}

.button {
	position: relative;
	padding: 1em 1.5em;
	border: none;
	background-color: transparent;
	cursor: pointer;
	outline: none;
	font-size: 18px;
	margin: 1em 0.8em;
	transition: all 100ms ease-in-out;
	border-radius: 12px 0;
}
.button:active {
	background-color: darkcyan;
	transition: all 100ms ease-in-out;
}
.expand::after,
.expand::before {
	content: '';
	display: block;
	position: absolute;
	width: 20%;
	height: 20%;
	border: 3px solid;
	transition: all 0.6s ease;
	border-radius: 12px;
}
.expand::after {
	bottom: 0;
	right: 0;
	border-top-color: transparent;
	border-left-color: transparent;
	border-bottom-color: $color;
	border-right-color: $color;
}
.expand::before {
	top: 0;
	left: 0;
	border-bottom-color: transparent;
	border-right-color: transparent;
	border-top-color: $color;
	border-left-color: $color;
}
.expand:hover:after,
.expand:hover:before {
	border-bottom-color: $color;
	border-right-color: $color;
	border-top-color: $color;
	border-left-color: $color;
	width: 130%;
	height: 120%;
}

// -----------------------------------------------------
.about {
	margin: 0 20px;
	font-size: 1.1rem;
}
* {
	color: white;
}
.fas {
	font-size: 3rem;
	transition: all 100ms ease-in-out;
	padding: 20px;
}
.resume {
	display: inline-block;
	font-family: 'Tajawal', sans-serif;
	font-size: 50%;
	margin: 13px 20px;
	vertical-align: bottom;
}
.resume-wrapper {
	cursor: pointer;
}
.fas:hover {
	color: $color;
	transform: scale(1.1);
	transition: all 100ms ease-in-out;
	.resume {
		color: $color;
	}
}

.message {
	border-radius: 25px;
	padding: 20px;
	min-width: 40vw;
	height: auto;
	margin: 0 auto;
	display: table;
}
@media screen and (min-width: 600px) {
	.message {
		width: 40vw;
		margin-top: 50px;
	}
}
.submit {
	padding: 15px 70px;
	font-size: 1.5em;
	text-align: center;
	cursor: pointer;
	outline: none;
	color: #fff;
	border: none;
	border-radius: 15px;
	box-shadow: 9px 9px #333;
	margin: 0 auto;
	display: table;
	background: linear-gradient(-45deg, #4aafb8, #c8b80e);
}
.submit:active {
	box-shadow: 5px 5px #222;
	transform: translateY(4px);
}
input[type=text], input[type=email], textarea {
	border-radius: 25px;
	width: 100%;
	padding: 15px;
	margin: 5px 0 22px 0;
	border: none;
	color: black;
	background: #f1f1f1;
	opacity: 0.9;
	text-align: left;
	font-size: 1.2rem;
}
input[type=text]:focus, input[type=password]:focus {
	background-color: #ddd;
	outline: none;
}

.form-inputs {
	visibility: hidden;
	height: 0px;
	transition: height 200ms ease-in-out;
	transition-delay: 200ms;
}

// reveal input animations
.reveal-inputs {
	$animation: reveal 600ms ease-in-out forwards;
	height: 293.4px;
	visibility: visible;

	input, textarea {
		opacity: 0;
		animation: $animation;
		-webkit-animation: $animation;
		-moz-animation:    $animation;
		-o-animation:      $animation;
		-ms-transition:    $animation;
		animation:         $animation;
	}
	input:nth-child(1) {
		animation-delay: 100ms;
	}
	input:nth-child(2) {
		animation-delay: 200ms;
	}
	textarea {
		animation-delay: 200ms;
	}
	@keyframes reveal {
		from {
			transform: scale(0.5);
		}
		to {
			opacity: 1;
			transform: scale(1);
		}
	}
}
</style>