<template>
	<div class="home" key="home">
		<div class="to-admin" :style="{width: steps + '%'}"></div>
		<Title @toAdmin="stepsToAdmin" @scroll="goTo"></Title>
		<div class="svgs after-title"></div>
		<AboutMe></AboutMe>
		<div class="svgs after-about"></div>
		<Education @toAdmin="stepsToAdmin"></Education>
		<div class="svgs after-education"></div>
		<Experience @toAdmin="stepsToAdmin"></Experience>
		<div class="svgs after-experience"></div>
		<Skills @toAdmin="stepsToAdmin"></Skills>
		<div class="svgs after-skills"></div>
		<Projects></Projects>
		<div class="svgs after-projects"></div>
		<ContactMe></ContactMe>
		<div class="svgs after-contact-me"></div>
		<Footer @toAdmin="stepsToAdmin"></Footer>
	</div>
</template>

<script>
// @ is an alias to /src
import Title from '@/components/Title.vue'
import AboutMe from '@/components/AboutMe.vue'
import Education from '@/components/Education.vue'
import Experience from '@/components/Experience.vue'
import Skills from '@/components/Skills.vue'
import Projects from '@/components/Projects.vue'
import ContactMe from '@/components/ContactMe.vue'
import Footer from '@/components/Footer.vue'

import firebase from 'firebase/compat'
require('firebase/auth')

export default {
	name: 'Home',
	components: {
		Title,
		AboutMe,
		Education,
		Experience,
		Skills,
		Projects,
		ContactMe,
		Footer
	},
	data() {
		return {
			steps: 0,
			logoClick: false,
			SAKEClick: false,
			expClick: false,
			skillExcerptClick: false,
			footerClick: false
		}
	},
	methods: {
		async signOut() {
			await firebase.auth().signOut();
		},
		stepsToAdmin(temp) {
			switch(temp) {
				case 'logoClick':
					if(this.logoClick == false) {
						this.logoClick = true;
						this.steps += 20;
					}
					break;
				case 'SAKEClick':
					if(this.SAKEClick == false) {
						this.SAKEClick = true;
						this.steps += 20;
					}
					break;
				case 'expClick':
					if(this.expClick == false) {
						this.expClick = true;
						this.steps += 20;
					}
					break;
				case 'skillExcerptClick':
					if(this.skillExcerptClick == false) {
						this.skillExcerptClick = true;
						this.steps += 20;
					}
					break;
				case 'footerClick':
					if(this.footerClick == false) {
						this.footerClick = true;
						this.steps += 20;
					}
					break;
			}
			this.toAdminOrNo();
		},
		toAdminOrNo() {
			if(this.steps == 100) {
				setTimeout(() => {
					this.$router.replace('/admin-login');
				}, 500)
			}
		},
		goTo(destination) {
			document.getElementById(destination).scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		}
	},
	mounted() {
		this.signOut();
	}
}
</script>

<style lang="scss">
.to-admin {
	width: 100%;
    height: 5px;
    background: sandybrown;
    position: fixed;
	transition: all 250ms ease-in-out;
	border-radius: 10px;
    z-index: 2;
}

.svgs {
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	background-position: center;
}

.title {
	background-image: url(../assets/in-page-svgs/title.svg);
	height: 100vh;
	position: absolute;
	z-index: -1;
	max-height: 23em;
}
.after-title {
	background-image: url(../assets/in-page-svgs/after-title.svg);
	height: 30vw;
	aspect-ratio: 960/300;
}
.after-about {
	background-image: url(../assets/in-page-svgs/after-about.svg);
	aspect-ratio: 960/130;
	height: 14vw;
}
.after-education {
	background-image: url(../assets/in-page-svgs/after-education.svg);
	aspect-ratio: 960/130;
	height: 16vw;
}
.after-experience {
	background-image: url(../assets/in-page-svgs/after-experience.svg);
	aspect-ratio: 960/130;
	height: 16vw;
}
.after-skills {
	background-image: url(../assets/in-page-svgs/after-skills.svg);
	aspect-ratio: 960/130;
	height: 16vw;
}
.after-projects {
	background-image: url(../assets/in-page-svgs/after-projects.svg);
	aspect-ratio: 960/130;
	height: 16vw;
	transform: rotateY(180deg)
}
.after-contact-me {
	background-image: url(../assets/in-page-svgs/after-contact-me.svg);
	aspect-ratio: 960/130;
	height: 14vw;
}
</style>