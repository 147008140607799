<template>
    <section id="education">
        <h2 class="sub-head">Education</h2>
        <div class="row">
            <div class="col-md-6 img florida-tech">
                <i class="fas fa-calendar-alt" style="display: flex"><p class="year">&emsp; 2022 - 2024</p></i>
                <p class="achievement">Master's in Computer Science</p>
                <p class="name"><i class="fas fa-map-marker-alt"></i>Florida Institute of Technology, Melfourne, FL, USA</p>
            </div>
            <div class="col-md-6 img sakec" @click="$emit('toAdmin', 'SAKEClick')">
                <i class="fas fa-calendar-alt" style="display: flex"><p class="year">&emsp; 2016 - 2020</p></i>
                <p class="achievement">Bachelor of Engineering in Electronics & Telecommunication</p>
                <p class="name"><i class="fas fa-map-marker-alt"></i>Shah & Anchor Kutchhi Engineering College, Mumbai, India</p>
            </div>
            <div class="col-md-6 img pvb">
                <i class="fas fa-calendar-alt" style="display: flex"><p class="year">&emsp; 2015 - 2016</p></i>
                <p class="achievement">Higher Secondary Certificate Examination</p>
                <p class="name">
                    <i class="fas fa-map-marker-alt"></i>
                    Pune Vidhya Bhavan, Mumbai, India
                </p>
            </div>
            <div class="col-md-6 img cvsl">
                <i class="fas fa-calendar-alt" style="display: flex"><p class="year">&emsp; 2014 - 2015</p></i>
                <p class="achievement">International Baccalaureate Diploma course</p>
                <p class="name"><i class="fas fa-map-marker-alt"></i>The Cathedral Vidya School, Lonavala, India</p>
            </div>
            <div class="col-md-6 img hfhs">
                <i class="fas fa-calendar-alt" style="display: flex"><p class="year">&emsp; 2013 - 2014</p></i>
                <p class="achievement">Secondary School Certificate Examination</p>
                <p class="name">
                    <i class="fas fa-map-marker-alt"></i>
                    Holy Family High School, Mumbai, India
                </p>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
#education {
	margin: 2.5rem 0;
}

/* image */
.img {
    box-shadow: inset 0 0 0 100vw rgba(0,0,0,0.6);
    padding: 20px;
    border-radius: 20px;
    max-width: 25em;
    margin: 20px;
    transition: all 1s ease-in-out;
}
.florida-tech {
    background: url('../assets/florida-tech.png') center center;
    background-size: cover;
}
.sakec {
    background: url('../assets/sakec.jpg') center center;
    background-size: cover;
}
.cvsl {
    background: url('../assets/cvsl.jpg') center center;
    background-size: cover;
}
.pvb {
    background: url('../assets/pvb.jpg') center center;
    background-size: cover;
}
.hfhs {
    background: url('../assets/hfhs.jpg') center center;
    background-size: cover;
}

@media screen and (max-width: 859px) {
    .img {
        max-width: 90vw;
        transition: all 1s ease-in-out;
    }
}

.row {
    margin: 0 0;
    justify-content: center;
    border: 0;
}

/* ---------------------- */
.fas {
    color: white;
    text-align: left;
    font-size: 1.1rem;
    margin-right: 10px;
}
.year {
	font-family: 'Tajawal', sans-serif;
}
.achievement {
    font-size: 1.5rem;
    color: white;
    text-align: left;
}
.name {
    font-size: 1rem;
    color: white;
    text-align: left;
    font-style: italic;
    margin: 0;
}

</style>