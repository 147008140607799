<template>
	<section id="title">
		<div class="header">
			<p class="intro">Greetings and welcome, my name is</p>
			<svg viewBox="102.844 81.25 305.984 156.641" xmlns="http://www.w3.org/2000/svg">
				<path id="l" class="first-name" d="M 402.725 119.099 L 402.725 206.809 L 386.605 206.809 L 386.605 119.099 L 402.725 119.099 Z" transform="matrix(1, 0, 0, 1, 0, 0)" stroke="rgb(92, 216, 227)" fill="rgb(92, 216, 227)" />
				<path id="u" class="first-name" d="M 373.974 204.939 L 368.704 197.619 C 367.411 198.786 366.034 199.889 364.574 200.929 C 363.107 201.962 361.574 202.859 359.974 203.619 C 358.367 204.379 356.717 204.986 355.024 205.439 C 353.324 205.886 351.594 206.109 349.834 206.109 C 345.967 206.109 342.334 205.426 338.934 204.059 C 335.534 202.692 332.557 200.749 330.004 198.229 C 327.444 195.709 325.431 192.642 323.964 189.029 C 322.497 185.416 321.764 181.362 321.764 176.869 L 321.764 142.189 L 337.704 142.189 L 337.704 176.869 C 337.704 178.982 338.027 180.869 338.674 182.529 C 339.314 184.189 340.184 185.586 341.284 186.719 C 342.377 187.852 343.654 188.712 345.114 189.299 C 346.581 189.879 348.154 190.169 349.834 190.169 C 351.474 190.169 353.027 189.789 354.494 189.029 C 355.961 188.269 357.241 187.262 358.334 186.009 C 359.427 184.762 360.284 183.349 360.904 181.769 C 361.531 180.182 361.844 178.549 361.844 176.869 L 361.844 142.189 L 377.844 142.189 L 377.844 204.939 L 373.974 204.939 Z" transform="matrix(1, 0, 0, 1, 0, 0)" stroke="rgb(218, 73, 199)" fill="rgb(218, 73, 199)" />
				<path id="h" class="first-name" d="M 270.577 167.839 L 270.577 203.699 L 254.577 203.699 L 254.577 115.989 L 270.577 115.989 L 270.577 143.699 C 272.49 142.252 274.464 141.226 276.497 140.619 C 278.524 140.019 280.594 139.719 282.707 139.719 C 286.574 139.719 290.207 140.449 293.607 141.909 C 297 143.376 299.957 145.389 302.477 147.949 C 304.997 150.509 306.99 153.496 308.457 156.909 C 309.924 160.329 310.657 163.972 310.657 167.839 L 310.657 203.699 L 294.537 203.699 L 294.537 167.839 L 294.657 167.839 C 294.657 166.199 294.344 164.646 293.717 163.179 C 293.097 161.719 292.24 160.439 291.147 159.339 C 290.054 158.246 288.784 157.389 287.337 156.769 C 285.89 156.142 284.347 155.829 282.707 155.829 C 281.027 155.829 279.444 156.142 277.957 156.769 C 276.477 157.389 275.19 158.246 274.097 159.339 C 272.997 160.439 272.137 161.719 271.517 163.179 C 270.89 164.646 270.577 166.199 270.577 167.839 Z" transform="matrix(1, 0, 0, 1, 0, 0)" stroke="rgb(184, 213, 65)" fill="rgb(184, 213, 65)" />
				<path id="a" class="first-name" d="M 243.723 137.327 L 243.723 199.967 L 239.853 199.967 L 233.643 191.357 C 232.116 192.724 230.503 194 228.803 195.187 C 227.103 196.38 225.316 197.417 223.443 198.297 C 221.57 199.177 219.646 199.87 217.673 200.377 C 215.7 200.884 213.696 201.137 211.663 201.137 C 207.25 201.137 203.1 200.397 199.213 198.917 C 195.326 197.43 191.93 195.27 189.023 192.437 C 186.11 189.604 183.813 186.137 182.133 182.037 C 180.453 177.937 179.613 173.27 179.613 168.037 C 179.613 163.15 180.453 158.667 182.133 154.587 C 183.813 150.507 186.11 147 189.023 144.067 C 191.93 141.14 195.326 138.867 199.213 137.247 C 203.1 135.62 207.25 134.807 211.663 134.807 C 213.696 134.807 215.71 135.064 217.703 135.577 C 219.696 136.084 221.63 136.784 223.503 137.677 C 225.376 138.577 227.163 139.634 228.863 140.847 C 230.563 142.06 232.156 143.367 233.643 144.767 L 239.853 137.327 L 243.723 137.327 Z M 227.603 168.037 C 227.603 165.85 227.183 163.73 226.343 161.677 C 225.503 159.624 224.36 157.807 222.913 156.227 C 221.473 154.647 219.786 153.377 217.853 152.417 C 215.92 151.464 213.856 150.987 211.663 150.987 C 209.476 150.987 207.416 151.357 205.483 152.097 C 203.55 152.837 201.87 153.93 200.443 155.377 C 199.023 156.824 197.9 158.61 197.073 160.737 C 196.253 162.864 195.843 165.297 195.843 168.037 C 195.843 170.77 196.253 173.2 197.073 175.327 C 197.9 177.454 199.023 179.24 200.443 180.687 C 201.87 182.134 203.55 183.227 205.483 183.967 C 207.416 184.714 209.476 185.087 211.663 185.087 C 213.856 185.087 215.92 184.607 217.853 183.647 C 219.786 182.694 221.473 181.424 222.913 179.837 C 224.36 178.257 225.503 176.44 226.343 174.387 C 227.183 172.34 227.603 170.224 227.603 168.037 Z" transform="matrix(1, 0, 0, 1, 0, 0)" stroke="rgb(95, 167, 255)" fill="rgb(95, 167, 255)" />
				<path id="R" class="first-name" d="M 144.289 132.137 L 127.469 132.137 L 127.469 165.777 L 144.289 165.777 C 146.589 165.777 148.756 165.337 150.789 164.457 C 152.822 163.577 154.599 162.374 156.119 160.847 C 157.646 159.327 158.849 157.54 159.729 155.487 C 160.602 153.44 161.039 151.264 161.039 148.957 C 161.039 146.65 160.602 144.474 159.729 142.427 C 158.849 140.374 157.646 138.584 156.119 137.057 C 154.599 135.537 152.822 134.337 150.789 133.457 C 148.756 132.577 146.589 132.137 144.289 132.137 Z M 127.469 182.527 L 127.469 199.347 L 110.649 199.347 L 110.649 115.327 L 144.289 115.327 C 147.369 115.327 150.336 115.727 153.189 116.527 C 156.042 117.327 158.709 118.46 161.189 119.927 C 163.669 121.387 165.936 123.144 167.989 125.197 C 170.036 127.25 171.792 129.527 173.259 132.027 C 174.726 134.527 175.859 137.2 176.659 140.047 C 177.459 142.9 177.859 145.87 177.859 148.957 C 177.859 151.85 177.499 154.664 176.779 157.397 C 176.052 160.13 175.016 162.727 173.669 165.187 C 172.322 167.647 170.672 169.914 168.719 171.987 C 166.766 174.054 164.579 175.85 162.159 177.377 L 171.469 199.347 L 153.599 199.347 L 146.279 182.417 L 127.469 182.527 Z" transform="matrix(1, 0, 0, 1, 0, 0)" stroke="rgb(255, 95, 95)" fill="rgb(255, 95, 95)" />
			</svg>
			&emsp;
			<svg viewBox="17.078 80.297 354.328 149.188" xmlns="http://www.w3.org/2000/svg">
				<path id="a" class="last-name" d="M 365.302 136.086 L 365.302 198.726 L 361.432 198.726 L 355.222 190.116 C 353.695 191.483 352.082 192.759 350.382 193.946 C 348.682 195.139 346.895 196.176 345.022 197.056 C 343.149 197.936 341.225 198.629 339.252 199.136 C 337.279 199.643 335.275 199.896 333.242 199.896 C 328.829 199.896 324.679 199.156 320.792 197.676 C 316.905 196.189 313.509 194.029 310.602 191.196 C 307.689 188.363 305.392 184.896 303.712 180.796 C 302.032 176.696 301.192 172.029 301.192 166.796 C 301.192 161.909 302.032 157.426 303.712 153.346 C 305.392 149.266 307.689 145.759 310.602 142.826 C 313.509 139.899 316.905 137.626 320.792 136.006 C 324.679 134.379 328.829 133.566 333.242 133.566 C 335.275 133.566 337.289 133.823 339.282 134.336 C 341.275 134.843 343.209 135.543 345.082 136.436 C 346.955 137.336 348.742 138.393 350.442 139.606 C 352.142 140.819 353.735 142.126 355.222 143.526 L 361.432 136.086 L 365.302 136.086 Z M 349.182 166.796 C 349.182 164.609 348.762 162.489 347.922 160.436 C 347.082 158.383 345.939 156.566 344.492 154.986 C 343.052 153.406 341.365 152.136 339.432 151.176 C 337.499 150.223 335.435 149.746 333.242 149.746 C 331.055 149.746 328.995 150.116 327.062 150.856 C 325.129 151.596 323.449 152.689 322.022 154.136 C 320.602 155.583 319.479 157.369 318.652 159.496 C 317.832 161.623 317.422 164.056 317.422 166.796 C 317.422 169.529 317.832 171.959 318.652 174.086 C 319.479 176.213 320.602 177.999 322.022 179.446 C 323.449 180.893 325.129 181.986 327.062 182.726 C 328.995 183.473 331.055 183.846 333.242 183.846 C 335.435 183.846 337.499 183.366 339.432 182.406 C 341.365 181.453 343.052 180.183 344.492 178.596 C 345.939 177.016 347.082 175.199 347.922 173.146 C 348.762 171.099 349.182 168.983 349.182 166.796 Z" transform="matrix(1, 0, 0, 1, 0, 0)" fill="rgb(235, 128, 15)" stroke="rgb(235, 128, 15)" />
				<path id="t" class="last-name" d="M 295.576 198.726 L 283.446 198.726 C 279.58 198.726 275.946 197.993 272.546 196.526 C 269.146 195.066 266.166 193.056 263.606 190.496 C 261.053 187.936 259.043 184.956 257.576 181.556 C 256.11 178.156 255.376 174.523 255.376 170.656 L 255.376 152.026 L 247.586 152.026 L 247.586 136.086 L 255.376 136.086 L 255.376 111.016 L 271.316 111.016 L 271.316 136.086 L 295.576 136.086 L 295.576 152.026 L 271.316 152.026 L 271.316 170.656 C 271.316 172.336 271.63 173.909 272.256 175.376 C 272.876 176.843 273.736 178.123 274.836 179.216 C 275.93 180.309 277.216 181.179 278.696 181.826 C 280.183 182.466 281.766 182.786 283.446 182.786 L 295.576 182.786 L 295.576 198.726 Z" transform="matrix(1, 0, 0, 1, 0, 0)" fill="rgb(255, 95, 95)" stroke="rgb(255, 95, 95)" />
				<path id="h" class="last-name" d="M 200.539 162.866 L 200.539 198.726 L 184.539 198.726 L 184.539 111.016 L 200.539 111.016 L 200.539 138.726 C 202.452 137.279 204.425 136.253 206.459 135.646 C 208.485 135.046 210.555 134.746 212.669 134.746 C 216.535 134.746 220.169 135.476 223.569 136.936 C 226.962 138.403 229.919 140.416 232.439 142.976 C 234.959 145.536 236.952 148.523 238.419 151.936 C 239.885 155.356 240.619 158.999 240.619 162.866 L 240.619 198.726 L 224.499 198.726 L 224.499 162.866 L 224.619 162.866 C 224.619 161.226 224.305 159.673 223.679 158.206 C 223.059 156.746 222.202 155.466 221.109 154.366 C 220.015 153.273 218.745 152.416 217.299 151.796 C 215.852 151.169 214.309 150.856 212.669 150.856 C 210.989 150.856 209.405 151.169 207.919 151.796 C 206.439 152.416 205.152 153.273 204.059 154.366 C 202.959 155.466 202.099 156.746 201.479 158.206 C 200.852 159.673 200.539 161.226 200.539 162.866 Z" transform="matrix(1, 0, 0, 1, 0, 0)" fill="rgb(95, 167, 255)" stroke="rgb(95, 167, 255)" />
				<path id="e" class="last-name" d="M 176.514 147.226 L 146.514 183.376 C 147.141 183.569 147.768 183.696 148.394 183.756 C 149.021 183.816 149.644 183.846 150.264 183.846 C 151.831 183.846 153.338 183.629 154.784 183.196 C 156.224 182.769 157.581 182.156 158.854 181.356 C 160.121 180.556 161.254 179.579 162.254 178.426 C 163.248 177.273 164.058 175.993 164.684 174.586 L 176.404 186.366 C 174.918 188.473 173.208 190.366 171.274 192.046 C 169.341 193.726 167.251 195.153 165.004 196.326 C 162.758 197.499 160.394 198.386 157.914 198.986 C 155.434 199.593 152.884 199.896 150.264 199.896 C 145.851 199.896 141.701 199.076 137.814 197.436 C 133.928 195.796 130.531 193.513 127.624 190.586 C 124.711 187.653 122.414 184.166 120.734 180.126 C 119.054 176.079 118.214 171.636 118.214 166.796 C 118.214 161.829 119.054 157.296 120.734 153.196 C 122.414 149.096 124.711 145.599 127.624 142.706 C 130.531 139.819 133.928 137.576 137.814 135.976 C 141.701 134.369 145.851 133.566 150.264 133.566 C 152.884 133.566 155.444 133.879 157.944 134.506 C 160.444 135.133 162.818 136.033 165.064 137.206 C 167.311 138.373 169.411 139.806 171.364 141.506 C 173.318 143.206 175.034 145.113 176.514 147.226 Z M 136.094 175.116 L 154.724 150.386 C 153.978 150.113 153.244 149.936 152.524 149.856 C 151.804 149.783 151.051 149.746 150.264 149.746 C 148.078 149.746 146.018 150.146 144.084 150.946 C 142.151 151.746 140.471 152.886 139.044 154.366 C 137.624 155.853 136.501 157.643 135.674 159.736 C 134.854 161.823 134.444 164.176 134.444 166.796 C 134.444 167.383 134.474 168.046 134.534 168.786 C 134.594 169.526 134.694 170.276 134.834 171.036 C 134.968 171.803 135.131 172.536 135.324 173.236 C 135.524 173.943 135.781 174.569 136.094 175.116 Z" transform="matrix(1, 0, 0, 1, 0, 0)" fill="rgb(218, 73, 199)" stroke="rgb(218, 73, 199)" />
				<path id="M" class="last-name" d="M 108.261 138.726 L 108.261 198.726 L 91.501 198.726 L 91.501 138.726 C 91.501 137.553 91.278 136.459 90.831 135.446 C 90.384 134.433 89.778 133.543 89.011 132.776 C 88.251 132.016 87.364 131.423 86.351 130.996 C 85.331 130.563 84.238 130.346 83.071 130.346 C 81.898 130.346 80.804 130.563 79.791 130.996 C 78.771 131.423 77.881 132.016 77.121 132.776 C 76.361 133.543 75.764 134.433 75.331 135.446 C 74.904 136.459 74.691 137.553 74.691 138.726 L 74.691 198.726 L 57.871 198.726 L 57.871 138.726 C 57.871 137.553 57.658 136.459 57.231 135.446 C 56.798 134.433 56.201 133.543 55.441 132.776 C 54.681 132.016 53.791 131.423 52.771 130.996 C 51.758 130.563 50.664 130.346 49.491 130.346 C 48.318 130.346 47.224 130.563 46.211 130.996 C 45.198 131.423 44.311 132.016 43.551 132.776 C 42.784 133.543 42.188 134.433 41.761 135.446 C 41.328 136.459 41.111 137.553 41.111 138.726 L 41.111 198.726 L 24.301 198.726 L 24.301 138.726 C 24.301 135.253 24.954 131.983 26.261 128.916 C 27.568 125.849 29.364 123.173 31.651 120.886 C 33.938 118.599 36.614 116.803 39.681 115.496 C 42.748 114.183 46.018 113.526 49.491 113.526 C 52.618 113.526 55.628 114.083 58.521 115.196 C 61.408 116.309 64.004 117.923 66.311 120.036 C 68.618 117.923 71.204 116.309 74.071 115.196 C 76.944 114.083 79.944 113.526 83.071 113.526 C 86.544 113.526 89.814 114.183 92.881 115.496 C 95.948 116.803 98.624 118.599 100.911 120.886 C 103.198 123.173 104.994 125.849 106.301 128.916 C 107.608 131.983 108.261 135.253 108.261 138.726 Z" fill="rgb(184, 213, 65)" stroke="rgb(184, 213, 65)"/>
			</svg>
			<p class="designation">{{ designation }}</p>
		</div>
		<img @click="$emit('toAdmin', 'logoClick')" src="../../public/logo.png" class="logo" alt="logo">
		<div class="scroll-down" @click="$emit('scroll', 'about')">
			<div class="arrow arrow-one"></div>
			<div class="arrow arrow-two"></div>
			<div class="arrow arrow-three"></div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'Title',
	data() {
		return {
			name: this.$store.state.name,
			designation: this.$store.state.designation
		}
	}
}

</script>


<style lang="scss" scoped>
svg {
	height: 20vw;
	max-height: 140px;
}

$delay: -2s;
.first-name, .last-name {
	stroke-dashoffset: 0;
	stroke-dasharray: 600;
	stroke-width: 3px;
	transition: fill 3.5s linear forwards;
	animation: dash 3.5s linear forwards;
}
// first name
.first-name:nth-child(5) {
	animation-delay: $delay;
}
.first-name:nth-child(4) {
	animation-delay: $delay + 0.25s;
}
.first-name:nth-child(3) {
	animation-delay: $delay + 0.5s;
}
.first-name:nth-child(2) {
	animation-delay: $delay + 0.75s;
}
.first-name:nth-child(1) {
	animation-delay: $delay + 1s;
}
// last name
.last-name:nth-child(5) {
	animation-delay: $delay + 1.25s;
}
.last-name:nth-child(4) {
	animation-delay: $delay + 1.5s;
}
.last-name:nth-child(3) {
	animation-delay: $delay + 1.75s;
}
.last-name:nth-child(2) {
	animation-delay: $delay + 2s;
}
.lasts-name:nth-child(1) {
	animation-delay: $delay + 2.25s;
}

@keyframes dash {
	0%,
	50% {
		stroke-dashoffset: 600;
		fill-opacity: 0;
	}
	to {
		stroke-dashoffset: 0;
		fill-opacity: 1;
	}
}

#title {
	display: flex;
	text-align: left;
	justify-content: space-evenly;
	align-items: center;
	min-height: 100vh;
}

// --------------------------------------------------------------------

.header {
	width: 50%;
}

.intro {
	color: white;
	margin: 0;
	font-size: 1.3rem;
}

.designation{
	font-size: 2rem;
	font-weight: 700;
	color: white;
	margin: 0;
}

// ---------------------------------------------------------------------

.scroll-down {
	height: 50px;
	width: 30px;
	border: 2px solid white;
	background-color: white;
	position: absolute;
	left: 50%;
	bottom: 20px;
	border-radius: 50px;
	cursor: pointer;
	opacity: 1;
	transform: translateX(-50%);
}
.arrow {
	position: absolute;
	left: 50%;
	top: 30%;
	border: 3px solid black;
	height: 10px;
	width: 10px;
	transform: translate(-50%, -100%) rotate(45deg);
	border-top: transparent;
	border-left: transparent;
	animation: go-down 3s ease-in-out infinite;
}
.arrow-two {
	animation-delay: 1s;
}
.arrow-three {
	animation-delay: 2s;
}
@keyframes go-down {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		top: 80%;
	}
}

// --------------------------------------------------------------------

.logo{ 
	margin-bottom: 30px;
	height: 10rem;
}
@media screen and (max-width: 930px) {
	.designation{
		font-size: 1.5rem;
	}
	.intro {
		font-size: 1rem;
	}
}
@media screen and (max-width: 650px) {
	#title {
		flex-direction: column-reverse;
		justify-content: center;
	}
	svg {
		height: 14vw;
	}
	.header {
		width: 70%;
	}
}
@media screen and (max-width: 466px) {
	svg {
		height: 18vw;
	}
}
@media screen and (max-width: 400px) {
	svg {
		height: 25vw;
	}
	.logo {
		height: 8rem;
	}
}
// ---------------------------------------
@media screen and (max-height: 580px) {
	.scroll-down {
		transform: translate(-50%, 20%) scale(0.8);
	}
}
@media screen and (max-height: 545px) {
	.scroll-down {
		transform: translate(-50%, 40%) scale(0.8);
	}
}

</style>